// Gatsby supports TypeScript natively!
import React from "react"
import {PageProps, Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"




const SecondPage = (props: PageProps) => (
    <Layout>
        <SEO title="My Work"/>
        <h1>My Work</h1>
        <p>I currently work at snapIoT. snapIoT, Inc., is an Internet of Things (IoT)-focused SaaS/PaaS software
            technology company that created a unique, patented, self-service-enabled, web and mobile device-based
            clinical trials platform, snapClinical™. </p>

    </Layout>
)

export default SecondPage
